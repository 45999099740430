$(function(e) {
    AOS.init();

    const borderElement = $('.nav-border');

    const makeHoverNavigation = () => {
        $('#mainNav').find('li').hover(function() {
            const {width, left} = $(this)[0].getBoundingClientRect();

            borderElement.css({
                width,
                left,
            })
        })
    }

    const makeHoverOnActiveList = () => {
        const activeList = $('#mainNav').find('.active');
        setTimeout(() => {
            const {width, left} = $(activeList)[0].getBoundingClientRect();
            borderElement.css({
                width,
                left,
            })
        }, 500);
    }

    makeHoverNavigation();
    makeHoverOnActiveList();

    // hamburger

    $('#hamburger').on('click', function() {
        $(this).toggleClass('is-open');
        $('#mainNav').toggleClass('is-nav-open');
        $('#black-wall').toggleClass('is-nav-open');
    });

    $('#black-wall').on('click', function() {
        $(this).toggleClass('is-nav-open');
        $('#mainNav').toggleClass('is-nav-open');
        $('#hamburger').toggleClass('is-open');
    });

    // count 

    let count = false;

    $(document).ready(function () {
        $(window).scroll(function () {
            var off = $(".home-split-section__content").offset().top; 
            var top = $(window).scrollTop() + $(window).height();
            if (off + 200 <= top) {
                my_method_to_invoke();
            }
        });
    });

    function my_method_to_invoke() {
        !count && $('.count').each(function () {
            const $this = $(this);
            $({ Counter: 0 }).animate({ Counter: $this.attr('count') }, {
                duration: 1000,
                easing: 'swing',
                step: function () {
                    $this.text(Math.ceil(this.Counter));
                }
            });
        });
        count = true;
    }
});
