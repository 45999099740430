const homeSwiper = new Swiper ('#home-swiper', {
    direction: 'horizontal',
    loop: true,
    parallax: true,
    breakpoints: {
      769: {
        direction: 'vertical',
      }
    },
    autoplay: {
      delay: 3000,
    },

    pagination: {
      el: '#home-swiper-pagination',
      clickable: true 
    },
    
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

const referenceSwiper = new Swiper ('#reference-swiper', {
  direction: 'horizontal',
  loop: true,
  parallax: true,
  autoplay: {
    delay: 3000,
  },

  pagination: {
    el: '#reference-swiper-pagination',
    clickable: true 
  },
  
})